import Image from "remix-image"

export default function BookImage({logo, title}: any) {
  return (
    <div className="h-auto w-72 bg-gradient-to-l from-sky-500 to-sky-300 rounded-md overflow-hidden book-transform">
      <Image
        className="w-auto h-full object-cover" alt={'gambar ' + title}
        src={logo}
        loaderUrl="/api/image"
        dprVariants={[1, 3]}
      />
    </div>
  )
}